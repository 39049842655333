<!-- =========================================================================================
  File Name: View.vue
  Description: Homestay View page
  ----------------------------------------------------------------------------------------
  Item Name: Booking manager admin
    Author: HopNepal
    Author URL: https://www.hopnepal.com
========================================================================================== -->

<template>
  <div id="page-user-view">
    <vs-alert
      color="danger"
      title="Booking Not Found"
      :active.sync="booking_not_found"
    >
      <span
        >Booking record with id: {{ $route.params.bookingId }} not found.
      </span>
      <span>
        <span>Check </span
        ><router-link :to="{ name: 'bookings' }" class="text-inherit underline"
          >All Bookings</router-link
        >
      </span>
    </vs-alert>

    <div id="user-data">
      <vx-card title="Booking Details" class="mb-base">
        <div class="vx-row">
          <!-- Information - Col 1 -->
          <div class="vx-col flex-1" id="account-info-col-1">
            <table>
              <tr>
                <td class="font-semibold">Booking ID</td>
                <td><b>{{ booking.id }}</b></td>
              </tr>
              <tr>
                <td class="font-semibold">Service Type</td>
                <td>{{ booking.service_type }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Name</td>
                <td>
                  <a
                    :href="`https://hopnepal.com/${booking.service_type}s/${booking.service_detail.slug}`"
                    >{{ booking.service_detail.name }}</a
                  >
                </td>
              </tr>
              <tr>
                <td class="font-semibold">Status</td>
                <td>{{ booking.status }}</td>
              </tr>
              <tr>
                <td class="font-semibold">From Date</td>
                <td>{{ booking.from_date }}</td>
              </tr>
              <tr>
                <td class="font-semibold">To Date</td>
                <td>{{ booking.to_date }}</td>
              </tr>
              <tr v-if="booking.room_type">
                <td class="font-semibold">Room Type</td>
                <td>{{ booking.room_type }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Total Amount</td>
                <td>{{ booking.total_amount }} USD</td>
              </tr>
              <tr>
                <td class="font-semibold">Payment Status</td>
                <td>{{ booking.payment_status }}</td>
              </tr>
            </table>
          </div>
          <!-- /Information - Col 1 -->

          <!-- Information - Col 2 -->
          <div
            class="vx-col flex-1"
            id="account-info-col-2"
            v-if="booking.guest_meta"
          >
            <h5 class="mb-base">Number of people</h5>
            <table>
              <tr>
                <td class="font-semibold">Adult</td>
                <td>{{ booking.guest_meta.adult }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Children</td>
                <td>{{ booking.guest_meta.children }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Number of rooms</td>
                <td>{{ booking.guest_meta.rooms }}</td>
              </tr>
            </table>
          </div>
          <!-- /Information - Col 2 -->
        </div>
      </vx-card>

      <vx-card title="User Details" class="mb-base">
        <div class="vx-row">
          <!-- Information - Col 1 -->
          <div class="vx-col flex-1" id="account-info-col-1">
            <table>
              <tr>
                <td class="font-semibold">Name</td>
                <td>{{ booking.user.full_name }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Email</td>
                <td>{{ booking.user.email }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Status</td>
                <td>{{ booking.user.status }}</td>
              </tr>
            </table>
          </div>
          <!-- /Information - Col 1 -->

          <!-- Information - Col 2 -->
          <div class="vx-col flex-1" v-if="booking.for_someone_else">
            <div
              class="vx-col flex-1"
              id="account-info-col-2"
              v-if="booking.for_someone_else !== false"
            >
              <h5 class="mb-base">Booked For someone else</h5>
              <table>
                <tr>
                  <td class="font-semibold">First Name</td>
                  <td>{{ booking.for_someone_else.first_name }}</td>
                </tr>
                <tr>
                  <td class="font-semibold">Last Name</td>
                  <td>{{ booking.for_someone_else.last_name }}</td>
                </tr>
                <tr>
                  <td class="font-semibold">Country</td>
                  <td>{{ booking.for_someone_else.country }}</td>
                </tr>
                <tr>
                  <td class="font-semibold">City</td>
                  <td>{{ booking.for_someone_else.city }}</td>
                </tr>
                <tr>
                  <td class="font-semibold">Address</td>
                  <td>{{ booking.for_someone_else.address }}</td>
                </tr>
              </table>
            </div>
            <div v-else>
              <p>Booked for someone else: {{ booking.for_someone_else }}</p>
            </div>
          </div>
          <!-- /Information - Col 2 -->
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import loaderMixin from "../../../mixins/loaderMixin";

export default {
  components: {
    AgGridVue,
  },
  mixins: [loaderMixin],
  computed: {
    ...mapGetters({
      booking: "booking/getBookingObj",
    }),
    getBookingId() {
      return this.$route.params.bookingId;
    },
  },
  created() {
    this.openLoading();
    this.fetchAndSetBookingById(this.getBookingId)
      .then((res) => this.closeLoading())
      .catch((error) => this.closeLoading());
  },
  methods: {
    ...mapActions("booking", ["fetchAndSetBookingById"]),
  },

  data() {
    return {
      booking_not_found: false,
    };
  },
};
</script>

<style lang="scss">

.danger {
  background: #802424;
  color: #fff;
}

#page-user-view {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: 0.8rem;
      word-break: break-all;
    }
  }
}

@media screen and (min-width: 1201px) and (max-width: 1211px),
  only screen and (min-width: 636px) and (max-width: 991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }
}

#page-user-list {
  width: 100%;
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
